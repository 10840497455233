//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainPage from "@/components/MainPage.vue";
import MainTitle from "@/components/MainTitle.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import ProductsTable from "@/components/Rating/ProductsTable";
import { dateRangeFromDate, today, dateRangeBoundaries } from "@/components/RangeCalendar.vue";
import CalendarMixin from "@/mixins/Calendar";
export default {
  name: "Home",
  mixins: [CalendarMixin],
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Аналитика и статистика товаров маркетплейса Wildberries, анализ лучших товаров для продаж на Вайлдберриз, анализ конкурентов" : "Аналитика и статистика товаров маркетплейса OZON, анализ лучших товаров для продаж на ОЗОН, анализ конкурентов",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder - сервис детальной аналитики Вайлдберриз: анализ категорий, подбор ниш и товаров для увеличения ваших продаж!" : "SalesFinder - сервис детальной аналитики OZON: анализ категорий, подбор ниш и товаров для увеличения ваших продаж!"
      }]
    };
  },
  data() {
    return {};
  },
  components: {
    MainPage,
    MainTitle,
    EntitySearch,
    ProductsTable
  },
  computed: {
    ratingBindings() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      const [date, date2] = dateRangeBoundaries(weekRange, this.calendarBoundaries);
      return {
        action: "rating/getRatingProducts",
        id: this._uid,
        routeParams: this.$route.params,
        reportSettings: {
          mp: this.$route.params.mp,
          metric: "revenue",
          date,
          date2,
          size: 20,
          ...this.$route.query
        },
        paginatable: false
      };
    }
  },
  methods: {
    searchLoader(query) {
      return this.$store.dispatch("product/getProductByName", {
        query,
        mp: this.$route.params.mp
      });
    },
    getRoute(item) {
      return {
        name: "ProductInfo",
        params: {
          p_id: item.p_id,
          mp: this.$route.params.mp
        }
      };
    }
  }
};